  var input = (document.getElementById('from_location'));
  
  var options = {
    types: ['geocode']
  };

  var autocomplete = new google.maps.places.Autocomplete(input, options);

  $( "#from_location" ).change(function() {
    var firstResult = $(".pac-container .pac-item:first .pac-item-query").text();
    var firstResultAdd = $(".pac-container .pac-item:first span:last").text();
    var suggest = firstResult + ', ' + firstResultAdd;
    var get_maps = $('body').attr('data-maps_url');
    $( "#from_location" ).val(suggest);

    $.getJSON('https://baggage.nl/' + get_maps + '?suggest=' + suggest, null, function (data) {
      
        if (data.results[0].place_id.length > 0) {
            var place = data.results[0];
            console.log(place);
            $('input#place_id').val(place.place_id);
            $('input#place_id').trigger('change');
            
            if ($('.product_info').length > 0) {
              if(place.formatted_address){
                $('.subheader').addClass('with_sub');
                $('.subheader #cur_place').html(place.formatted_address);
                $('.lostfound input[name="billing_city"]').val(place.address_components[0].long_name);
                $('.globalblue input[name="billing_city"]').val(place.address_components[0].long_name);
              } else {
                $('.subheader').removeClass('with_sub');
                $('.subheader h3').velocity('fadeOut');
              }
            }
            
            if (!place.geometry) {
              return;
            }
        }
    });
  });

  google.maps.event.addListener(autocomplete, 'place_changed', function() {

  var place = autocomplete.getPlace();
 
        if ($('.product_info').length > 0) {
          if(place.formatted_address){
            $('.subheader').addClass('with_sub');
            $('.subheader #cur_place').html(place.adr_address);
            $('.lostfound input[name="billing_city"]').val(place.name);
            $('.globalblue input[name="billing_city"]').val(place.address_components[0].long_name);
          } else {
            $('.subheader').removeClass('with_sub');
            $('.subheader h3').velocity('fadeOut');
          }
        }
        
        if (!place.geometry) {
          return;
        }
        
  });

  var input_second = (document.getElementById('from_location_second'));

  var autocomplete_second = new google.maps.places.Autocomplete(input_second, options);

  $( "#from_location_second" ).change(function() {
    var firstResult = $(".pac-container .pac-item:first .pac-item-query").text();
    var firstResultAdd = $(".pac-container .pac-item:first span:last").text();
    var suggest = firstResult + ', ' + firstResultAdd;
    var get_maps = $('body').attr('data-maps_url');

    $( "#from_location_second" ).val(suggest);

    $.getJSON('https://baggage.nl/' + get_maps + '?suggest=' + suggest, null, function (data) {
        if (data.results.length > 0) {
            var place = data.results[0];

            if (place.place_id) {
              var place_id = place.place_id;
              $('input#place_id_second').val(place_id);
            }
        }
    });
  });

  google.maps.event.addListener(autocomplete_second, 'place_changed', function() {
    var place = autocomplete_second.getPlace();


        if (!place.geometry) {
          return;
        }

        if (place.place_id) {
          var place_id = place.place_id;
          $('input#place_id_second').val(place_id);
        }
  });